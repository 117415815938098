import React, { Suspense, useState, useEffect, useRef } from 'react';
export default function Contact1e(props) {
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [mail, setMail] = useState();
  const [autocompleteFlag, setAutocompleteFlag] = useState();
  const [domainList, setDomainList] = useState();
  const inputEl = useRef(null);
  const [mailV, setMailV] = useState(true);
  const [mailError, setMailError] = useState();
  const [checkedItems, setCheckedItems] = useState({});
  const { dataLayer } = window;
  const [buttonFlg, setButtonFlg] = useState(false);
  var data = [];
  const mainDomainList = ["docomo.ne.jp", "ezweb.ne.jp", "gmail.com", "hotmail.com", "i.softbank.jp", "icloud.com", "outlook.com", "softbank.ne.jp", "yahoo.co.jp"];
  const allDomainList = ["aol.jp", "augps.ezweb.ne.jp", "biz.ezweb.ne.jp", "disney.ne.jp", "docomo.ne.jp", "dwmail.jp", "emnet.ne.jp", "emobile-s.ne.jp", "emobile.ne.jp", "ezweb.ne.jp", "gmail.com", "hotmail.com", "i.softbank.jp", "icloud.com", "ido.ne.jp", "jp-c.ne.jp", "jp-d.ne.jp", "jp-h.ne.jp", "jp-k.ne.jp", "jp-n.ne.jp", "jp-q.ne.jp", "jp-r.ne.jp", "jp-s.ne.jp", "jp-t.ne.jp", "live.jp", "mopera.net", "outlook.com", "pdx.ne.jp", "sky.tkc.ne.jp", "sky.tkk.ne.jp", "sky.tu-ka.ne.jp", "softbank.ne.jp", "uqmobile.jp", "vodafone.ne.jp", "wcm.ne.jp", "willcom.com", "y-mobile.ne.jp", "yahoo.co.jp", "ymobile.ne.jp", "ymobile1.ne.jp"];
  const checkLists = [
    "いつでも",
    "平日",
    "祝日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
    "日曜日",
  ];
  const input14Ref1 = useRef(null);
  const input14Ref2 = useRef(null);
  const input14Ref3 = useRef(null);
  const input14Ref4 = useRef(null);
  const input14Ref5 = useRef(null);
  const input14Ref6 = useRef(null);
  const input14Ref7 = useRef(null);
  const input14Ref8 = useRef(null);
  const input14Ref9 = useRef(null);
  const input14Ref10 = useRef(null);
  const input15Ref = useRef(null);
  const times = ['いつでも', '10時〜12時', '12時〜13時', '13時〜15時', '15時〜18時', '18時〜21時'];
  const [time, setTime] = useState('');
  const checkEmpty = (name, value) => {
    console.log("checkEmpty", name, value, checkedItems);
    if (((name === "mail" && !value) || (name !== "tel" && !mail))
      || ((name === "time" && !value) || (name !== "time" && !time)) || Object.keys(checkedItems).length === 0) { setButtonFlg(false) } else { setButtonFlg(true); setErrorMsg(''); }
  }
  const handleChange = (event) => {
    event.preventDefault();
    setErrorMsg('');
    let v;
    switch (event.target.name) {
      case 'mail':
        setMail(event.target.value);
        if (mailV) {
          dataLayer.push({ 'event': "mailV" });
          setMailV(false);
        }
        if (event.target.value.match(/@/)) {
          // @を追加してすぐの場合
          setAutocompleteFlag(true);
          let v = event.target.value;
          let url_search = v.indexOf('@');
          let url_txt = v.substring(url_search + 1);
          if (url_txt.length <= 0) {
            console.log(url_txt.length);
            setDomainList(mainDomainList);
          } else {
            console.log(url_txt);
            let newDomainList = allDomainList.filter(d => { return d.startsWith(url_txt); });
            setDomainList(newDomainList);
            console.log("newDomainList", newDomainList);
          }
        } else {
          // @が削除されてすぐのとき
          if (autocompleteFlag) {
            setAutocompleteFlag(false);
            setDomainList();
          }
        }
        break;
      case 'time':
        setTime(event.target.value);
        v = event.target.value;
        break;
      case 'time':
        setTime(event.target.value);
        v = event.target.value;
        break;
      default:
        break;
    }
    checkEmpty(event.target.name, v);
  };
  const checkChange = e => {
    console.log("e.relatedTarget", e.relatedTarget);
    if (e.target.checked === true) {
      console.log("true");
      const id = e.target.id;
      console.log("id", id);
      let labelElement = document.getElementById('id_' + id);
      console.log("labelElement", labelElement);
      labelElement.style.background = "lightsalmon";
      setCheckedItems({
        ...checkedItems,
        [e.target.id]: e.target.value
      })
      if (!time) { setButtonFlg(false); }
      else { setButtonFlg(true); setErrorMsg(''); }
    } else if (e.target.checked === false) {
      console.log("false");
      const id = e.target.id;
      console.log("id", id);
      let labelElement = document.getElementById('id_' + id);
      console.log("labelElement", labelElement);
      labelElement.style.background = "#fff";
      const copyCheckedItems = { ...checkedItems };
      delete copyCheckedItems[e.target.id];
      console.log("copyCheckedItems", copyCheckedItems);
      setCheckedItems(copyCheckedItems);
      if (!time || Object.keys(copyCheckedItems).length === 0) { setButtonFlg(false) } else { setButtonFlg(true); setErrorMsg(''); }
    }
  }
  const onDomainClick = (e) => {
    e.preventDefault();
    console.log("e.target.value", e.target.value);
    const domain = e.target.value;
    const atIndex = mail.indexOf('@');
    const mailName = mail.slice(0, atIndex);
    console.log("onDomainClick", domain, atIndex, mailName);
    setMail(mailName + '@' + domain);
    inputEl.current.focus();
    setAutocompleteFlag(false);
    setDomainList();
    setErrorMsg('');
  }
  const triggerNext = (event) => {
    setTrigger(true, props.triggerNextStep({ trigger: 'contact2c' }));
  }
  const onBlurSubmit = e => {
    console.log("onBlurSubmit", e.relatedTarget);
    e.preventDefault();
    if ((e.relatedTarget != null && e.relatedTarget.className.includes('domain-buttons'))||
    input14Ref1.current === e.relatedTarget ||
    input14Ref2.current === e.relatedTarget ||
    input14Ref3.current === e.relatedTarget ||
    input14Ref4.current === e.relatedTarget ||
    input14Ref5.current === e.relatedTarget ||
    input14Ref6.current === e.relatedTarget ||
    input14Ref7.current === e.relatedTarget ||
    input14Ref8.current === e.relatedTarget ||
    input14Ref9.current === e.relatedTarget ||
    input14Ref10.current === e.relatedTarget ||
    input15Ref.current === e.relatedTarget) { } else {
      if (!mail) {
        setErrorMsg('※メールアドレスが未入力です');
        return false;
      } else if (!mail.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
        setErrorMsg('※メールアドレスが不正です');
        return false;
      } else {
        setErrorMsg('');
      };
      if (Object.keys(checkedItems).length !== 0) {
        console.log("checkedItems", checkedItems);
        Object.entries(checkedItems).forEach(([key, value], index) => {
          data = data.concat({ name: 'day-of-week' + key, 'value': value })
        });
        setErrorMsg('');
      } else {
        console.log("checkedItems", checkedItems);
        setErrorMsg('※連絡希望曜日を選択して下さい');
        return false;
      };
      if (time) { setErrorMsg(''); data = data.concat({ name: 'time', value: time }); } else {
        setErrorMsg('※連絡希望時間帯が未入力です');
        return false;
      };
      // props.step.value = mail;
      // triggerNext();
    }
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit", e);
    if (e.relatedTarget != null && e.relatedTarget.className.includes('domain-buttons')) { } else {
      if (!mail) {
        setErrorMsg('※メールアドレスが未入力です');
        return false;
      } else if (!mail.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
        setErrorMsg('※メールアドレスが不正です');
        return false;
      } else {
        setErrorMsg('');
        inputEl.current.blur();
        data = data.concat({ name: 'mail', value: mail });
        // props.step.value = data;
        // triggerNext();
        const domain = mail.split('@')[1];
        console.log('domain',domain);
        var jsonData = JSON.stringify({'domain' : domain});
        console.log('jsonData',jsonData);
        fetch("/checkmx",{
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          body: jsonData
        })
        .then(res => res.json()) 
        .then((res) => {
          if(res.hasOwnProperty('error')){
          console.log('送信失敗1',res);
          setErrorMsg('※送信できないメールアドレスです');
          return false;
          }else{
            console.log('成功！',res);
            setErrorMsg('');
            props.step.value = data;
            triggerNext();
            return true;
          };
        })
        .catch((err) => {
          console.log('エラー',err);
        });
      };
    }
  }
  useEffect(() => {
    dataLayer.push({ 'event': "mailFocus" });
  }, []);
  return (
    <form className="formCss2 mailInfo back-white-wrapper" onSubmit={handleSubmit}>
      <label>メールアドレス：<input className="mailInput" ref={inputEl} type="mail" name="mail" value={mail} onChange={handleChange} onBlur={onBlurSubmit} autoFocus />
      </label><br></br>
      {autocompleteFlag && <ul className="contact1c-ul"> {domainList?.map(v => { return <li><button className={'domain-buttons button-' + v} value={v} onClick={onDomainClick}>{v}</button></li> })}</ul>
      }
      <label >連絡希望曜日：</label>
      <div className="form-check dayCheck">
        <label id="id_0" for="0" ><input id="0" className="day-of-week0 dayCheckBox" type="checkbox" name="inputNames" value="いつでも" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref1} />いつでも</label><br class="checkBoxBr1"></br>
        <label id="id_1" for="1"><input id="1" className="day-of-week1 dayCheckBox" type="checkbox" name="inputNames" value="平日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref2} />平日</label><br class="checkBoxBr2"></br>
        <label id="id_2" for="2"><input id="2" className="day-of-week2 dayCheckBox" type="checkbox" name="inputNames" value="祝日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref3} />祝日</label><br class="checkBoxBr3"></br>
        <label id="id_3" for="3"><input id="3" className="day-of-week3 dayCheckBox" type="checkbox" name="inputNames" value="月曜日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref4} />月曜日</label><br class="checkBoxBr4"></br>
        <label id="id_4" for="4"><input id="4" className="day-of-week4 dayCheckBox" type="checkbox" name="inputNames" value="火曜日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref5} />火曜日</label><br class="checkBoxBr5"></br>
        <label id="id_5" for="5"><input id="5" className="day-of-week5 dayCheckBox" type="checkbox" name="inputNames" value="水曜日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref6} />水曜日</label><br class="checkBoxBr6"></br>
        <label id="id_6" for="6"><input id="6" className="day-of-week6 dayCheckBox" type="checkbox" name="inputNames" value="木曜日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref7} />木曜日</label><br class="checkBoxBr7"></br>
        <label id="id_7" for="7"><input id="7" className="day-of-week7 dayCheckBox" type="checkbox" name="inputNames" value="金曜日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref8} />金曜日</label><br class="checkBoxBr8"></br>
        <label id="id_8" for="8"><input id="8" className="day-of-week8 dayCheckBox" type="checkbox" name="inputNames" value="土曜日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref9} />土曜日</label><br class="checkBoxBr9"></br>
        <label id="id_9" for="9"><input id="9" className="day-of-week9 dayCheckBox" type="checkbox" name="inputNames" value="日曜日" onChange={checkChange} onBlur={onBlurSubmit} ref={input14Ref10} />日曜日</label><br class="checkBoxBr10"></br>

      </div>
      <br></br>

      <label>連絡希望時間帯:
        <select className="timeOfDay" value={time} onChange={handleChange} name="time" onBlur={onBlurSubmit} ref={input15Ref}>
          <option value='' disabled>連絡希望時間帯を選択して下さい</option>
          {times?.map(d => { return <><option value={d}>{d}</option></> })}
        </select>
      </label><br></br>
      <p className='errMsg'>{errorMessage}</p>
      {buttonFlg && <button className="btn btn-success" formNoVlidate>
        次へ
      </button>}
    </form>
  );
} 