import React, {Suspense, useState, useEffect } from 'react';
import SetCities from './setCity';
import SetTowns from './setTowns';

export default function UserInfo(props){
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [sirName, setSirName] = useState();
  const [sirNameKana, setSirNameKana] = useState();
  const [firstName, setFirstName] = useState();
  const [firstNameKana, setFirstNameKana] = useState();
  const [kanaError, setKanaError] = useState();
  const [ageVal, setAgeVal] = useState();
  const [kyojyu, setKyojyu] = useState(false);
  const [prefecture, setPrefecture] = useState();
  const [cityValue, setCityValue] = useState();
  const [townSetValue, setTownSetValue] = useState();
  const [townValue, setTownValue] = useState();
  const [address, setAddress] = useState();
  const [activityChanged, setActivityChanged] = useState(false); //useState(true);
  const age = ["20歳未満","20代","30代","40代","50代","60代","70歳以上"];
  const prefectures = [ null, '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];
  const { dataLayer } = window;
  const handleChange = (event) => {
    dataLayer.push({'event': event.target.name});
  switch(event.target.name){
    case 'ageVal':
      setAgeVal(event.target.value);
        break;
    case 'prefecture':
      setPrefecture(event.target.value);
    break;
    case 'cityValue':
      setCityValue(event.target.value);
      setTownSetValue(event.target.value);
      break;
    case 'townValue':
      setTownValue(event.target.value);
        break;
    default:
      break
  }
  };
  const triggerNext = (event) =>{
    setTrigger(true, props.triggerNextStep({trigger: 'finish2'}))
  }
const handleSubmit = (event) => {
  event.preventDefault();
  let data = [];

  if(sirName){ setErrorMsg(''); data = data.concat({name: 'sir-name', value: sirName}); }else{
    setErrorMsg('※名字が未入力です');
    return false;
  };
  if(firstName){ 
    setErrorMsg(''); data = data.concat({name: 'first-name', value: firstName}); 
  }else{
    setErrorMsg('※名前が未入力です');
    return false;
  };
  if(sirNameKana){ 
    if(!sirNameKana.match(/^[ぁ-んー]*$/)){
    setErrorMsg(''); 
    setKanaError("平仮名でふりがなを入力して下さい");
    return false;
    }else{setErrorMsg(''); data = data.concat({name: 'sir-kana', value: sirNameKana});}
  }else{
    setErrorMsg('※名字（かな）が未入力です');
    return false;
  };
  if(firstNameKana){ 
    if(!sirNameKana.match(/^[ぁ-んー]*$/)){
      setErrorMsg(''); 
      setKanaError("平仮名でふりがなを入力して下さい");
      return false;
      }else{setErrorMsg(''); data = data.concat({name: 'first-kana', value: firstNameKana}); }
    }else{
    setErrorMsg('※名前（かな）が未入力です');
    return false;
  };
  if(ageVal){ setErrorMsg(''); data = data.concat({name: 'age', value: ageVal}); }else{
    setErrorMsg('※年代が未入力です');
    return false;
  };
  let userAddress ='';
  if(kyojyu){
    if(prefecture){setErrorMsg(''); }else{
      setErrorMsg('※都道府県が未入力です');
      return false;
    };
    if(cityValue){setErrorMsg(''); }else{
      setErrorMsg('※市区町村が未入力です');
      return false;
    };
    if(townValue){setErrorMsg(''); }else{
      setErrorMsg('※町域が未入力です');
      return false;
    };
    if(address){setErrorMsg(''); }else{
      setErrorMsg('※字/丁目以降が未入力です');
      return false;
    };
    userAddress = prefecture + cityValue + townValue + address;
  }else{
    const list2 = props.steps.getAgents.value;
    let prefecture2 = list2.find(a => a.name === 'bukken-address1');
    let cityValue2 = list2.find(a => a.name === 'bukken-address2');
    let townValue2 = list2.find(a => a.name === 'bukken-address3');
    let address2 = list2.find(a => a.name === 'bukken-address4');
    let name2 = list2.find(a => a.name === 'bukken-name');
    let goshitsu2 = list2.find(a => a.name === 'bukken-address5');
    if(prefecture2 !== undefined){userAddress = userAddress.concat(prefecture2.value)}
    if(cityValue2 !== undefined){userAddress = userAddress.concat(cityValue2.value)}
    if(townValue2 !== undefined){userAddress = userAddress.concat(townValue2.value)}
    if(address2 !== undefined){userAddress = userAddress.concat(address2.value)}
    if(name2 !== undefined){userAddress = userAddress.concat(' ', name2.value)}
    if(goshitsu2 !== undefined){userAddress = userAddress.concat(goshitsu2.value)}
  }
  
  if(userAddress){data = data.concat({name: 'address', value: userAddress})}
  // let jiki = props.steps.bukkenQa.value.jiki;
  // let reson = props.steps.bukkenQa.value.reson;
  let jiki = props.steps.bukkenQa.value.jiki;
  let reson = props.steps.bukkenQa.value.reson;
  if(jiki){ data = data.concat({name: 'jiki', value: jiki})};
  if(reson){ data = data.concat({name: 'reson', value: reson});};

//テストデータ
// data = data.concat({name: 'sir-name', value: 'テストユーザー'});
// data = data.concat({name: 'first-name', value: 'テストネーム'});
// data = data.concat({name: 'sir-kana', value: 'てすと'});
// data = data.concat({name: 'first-kana', value: 'てすと'});
// data = data.concat({name: 'age', value: '20代'});
// data = data.concat({name: 'address', value: '東京都世田谷区池尻1223334444'});
// data = data.concat({name: 'jiki', value: "できるだけ早く"});
// data = data.concat({name: 'reson', value: "金銭的な問題"});


    props.step.value = data;
    triggerNext();
    //setActivityChanged(true);
  };
  const onBlurFunc = (e) => {
    if(!e.target.value.match(/^[ぁ-んー]*$/)){
          setKanaError("平仮名でふりがなを入力して下さい")
    }else{
      setKanaError('');
    }
  }
  const inputChange = (event) =>{
    dataLayer.push({'event': event.target.name});
    switch(event.target.name){
      case 'sirName':
        setSirName(event.target.value)
      break;
      case 'sirNameKana':
        setSirNameKana(event.target.value)
      break;
      case 'firstName':
        setFirstName(event.target.value)
      break;
      case 'firstNameKana':
       setFirstNameKana(event.target.value);
      break;
      case 'address':
        setAddress(event.target.value)
      break;
      default:
        break;
    }
  }
  useEffect(() => {
    const list = props.steps.getAgents.value;
    let genkyo = list.find(a => a.name === 'bukken-state');
    if(genkyo.value  !=='居住中'){
      setKyojyu(true);
    };
  },[]);
  return (
    <form onSubmit={handleSubmit} className="formCss2 userInfo back-white-wrapper">
        <label>氏名：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className='spBr'></br>
        <input placeholder="名字" type="text" name="sirName" value={sirName} className="sirnameInput" onChange={inputChange}/>
        <input placeholder="名前" type="text" name="firstName" className="firstnameInput" value={firstName} onChange={inputChange}/></label><br></br>
        <label>ふりがな:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className='spBr'></br>
        <input placeholder="みょうじ" type="text" name="sirNameKana" className="sirnameInput" value={sirNameKana} onChange={inputChange} onBlur={onBlurFunc}/>
        <input placeholder="なまえ" type="text" name="firstNameKana" className="firstnameInput" value={firstNameKana} onChange={inputChange} onBlur={onBlurFunc}/></label><br></br>
        <p className='errMsg'>{kanaError}</p>
      <label>年代：
      <select value={ageVal} onChange={handleChange} name="ageVal" defaultValue=''>
        <option value='' disabled>年代を選んでください</option>
          {age?.map( d =>{return <><option value={d}>{d}</option></>})}
        </select>
      </label><br></br>
      {kyojyu&&   <>
        <label>都道府県
        <select value={prefecture} onChange={handleChange} name="prefecture" defaultValue="">
        <option value='' disabled>都道府県を選択して下さい</option>
          {prefectures?.map( d =>{return <><option value={d}>{d}</option></>})}
        </select>
        </label><br></br>
      <label>市区町村：
      <select value={cityValue} onChange={handleChange} defaultValue="" name='cityValue'>
      <option value='' disabled>市区町村を選択して下さい</option>
      <Suspense fallback={<p>Loading..</p>}>
      <SetCities property={prefecture}></SetCities>
      </Suspense>
      </select>
        </label><br></br>
        <label>
        町域：
      <select value={townValue} onChange={handleChange} defaultValue="" name='townValue'>
      <option value='' disabled>町域を選択して下さい</option>
      <Suspense fallback={<p>Loading..</p>}>
      <SetTowns prefecture={prefecture} townProperty={townSetValue}></SetTowns>
      </Suspense>
      </select>
        </label><br></br>
        <label>字/丁目以降：<input type="text" name="address" value={address} onChange={inputChange}/></label><br></br>
        </>}
        <p className='errMsg'>{errorMessage}</p>
        <button className="btn btn-success" disabled ={(activityChanged)?true:false}>
            次へ
            </button>
      </form>
    );
  }