import React, { Suspense, useState, useEffect, useRef } from 'react';
import SetCities from './setCity';
import SetTowns from './setTowns';
import { useKana } from 'react-use-kana';
import { useGenkyoContext } from '../context/contexts';

const CheckBox = ({ id, value, checked, onChange }) => {
  return (
    <input
      id={id}
      type="checkbox"
      name="inputNames"
      checked={checked}
      onChange={onChange}
      value={value}
    />
  )
}

export default function Contact2e(props) {
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [tel, setTel] = useState();
  const [telV, setTelV] = useState(true);
  const [prefV, setPrefV] = useState(true);
  const [townV, setTownV] = useState(true);
  const [cityV, setCityV] = useState(true);
  const [telError, setTelError] = useState();
  const [activityChanged, setActivityChanged] = useState(false);
  const [buttonFlg, setButtonFlg] = useState(false);
  const [sirName, setSirName] = useState();
  const [sirNameKana, setSirNameKana] = useState();
  const [firstName, setFirstName] = useState();
  const [firstKana, setFirstNameKana] = useState();
  const [kanaError, setKanaError] = useState();
  const [kyojyu, setKyojyu] = useState(false);
  const [prefecture, setPrefecture] = useState();
  const [cityValue, setCityValue] = useState();
  const [townSetValue, setTownSetValue] = useState();
  const [townValue, setTownValue] = useState();
  const [address, setAddress] = useState();
  const [sirNameV, setSirNameV] = useState(true);
  const [sirNameKanaV, setSirNameKanaV] = useState(true);
  const [firstNameV, setFirstNameV] = useState(true);
  const [firstKanaV, setFirstNameKanaV] = useState(true);
  const [addressV, setAddressV] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const {kyojyuState, setKyojyuState} = useGenkyoContext();
  const {
    kana: lastNameKana,
    setKanaSource: setLastNameKanaSource
  } = useKana();
  const {
    kana: firstNameKana,
    setKanaSource: setFirstNameKanaSource
  } = useKana();

  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const { dataLayer } = window;
  const prefectures = [null, '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

  const checkEmpty = (name, value) => {
    if (kyojyu) {
      if (((name === "sirName" && !value) || (name !== "sirName" && !sirName))
        || ((name === "sirNameKana" && !value) || (name !== "sirNameKana" && !sirNameKana))
        || ((name === "firstName" && !value) || (name !== "firstName" && !firstName))
        || ((name === "firstKana" && !value) || (name !== "firstKana" && !firstKana))
        || ((name === "prefecture" && !value) || (name !== "prefecture" && !prefecture))
        || ((name === "cityValue" && !value) || (name !== "cityValue" && !cityValue))
        || ((name === "townValue" && !value) || (name !== "townValue" && !townValue))
        || ((name === "address" && !value) || (name !== "address" && !address))
        || ((name === "tel" && !value) || (name !== "tel" && !tel))) { setButtonFlg(false) } else { setButtonFlg(true); setErrorMsg(''); }
    } else {
      if (((name === "sirName" && !value) || (name !== "sirName" && !sirName))
        || ((name === "sirNameKana" && !value) || (name !== "sirNameKana" && !sirNameKana))
        || ((name === "firstName" && !value) || (name !== "firstName" && !firstName))
        || ((name === "firstKana" && !value) || (name !== "firstKana" && !firstKana))
        || ((name === "tel" && !value) || (name !== "tel" && !tel))) { setButtonFlg(false) } else { setButtonFlg(true); setErrorMsg(''); }
    }
  }

  const handleChange = (event) => {
    let v;
    switch (event.target.name) {
      case 'tel':
        setTel(event.target.value);
        v = event.target.value;
        if (telV) {
          dataLayer.push({ 'event': "telV" });
          setTelV(false);
        }
        break;
      case 'prefecture':
        setPrefecture(event.target.value);
        v = event.target.value;
        if (prefV) {
          dataLayer.push({ 'event': "prefV" });
          setPrefV(false);
        }
        break;
      case 'cityValue':
        setCityValue(event.target.value);
        setTownSetValue(event.target.value);
        v = event.target.value;
        if (townV) {
          dataLayer.push({ 'event': "cityV" });
          setTownV(false);
        }
        break;
      case 'townValue':
        setTownValue(event.target.value);
        v = event.target.value;
        if (cityV) {
          dataLayer.push({ 'event': "townV" });
          setCityV(false);
        }
        break;

      default:
        break
    }
    checkEmpty(event.target.name, v);
  };

  const checkEnterEvent = (e) => {
    if (e.nativeEvent.inputType == 'deleteCompositionText') { return true } else { return false };
  }
  const inputChange = (event) => {
    let v;

    switch (event.target.name) {
      case 'sirName':
        setSirName(event.target.value)
        v = event.target.value;
        if (!checkEnterEvent(event)) {
          setLastNameKanaSource(v);
          setSirNameKana(lastNameKana);
        }
        if (sirNameV) {
          dataLayer.push({ 'event': "sirNameV" });
          setSirNameV(false);
        }
        break;
      case 'sirNameKana':
        setSirNameKana(event.target.value)
        v = event.target.value;
        if (sirNameKanaV) {
          dataLayer.push({ 'event': "sirKanaV" });
          setSirNameKanaV(false);
        }
        break;
      case 'firstName':
        setFirstName(event.target.value)
        v = event.target.value;
        if (!checkEnterEvent(event)) {
          setFirstNameKanaSource(v);
          setFirstNameKana(firstNameKana);
        }
        if (firstNameV) {
          dataLayer.push({ 'event': "firstNameV" });
          setFirstNameV(false);
        }
        break;
      case 'firstKana':
        setFirstNameKana(event.target.value);
        v = event.target.value;
        if (firstKanaV) {
          dataLayer.push({ 'event': "firstKanaV" });
          setFirstNameKanaV(false);
        }
        break;
      case 'address':
        setAddress(event.target.value);
        v = event.target.value;
        if (addressV) {
          dataLayer.push({ 'event': "addressV" });
          setAddressV(false);
        }
        break;
      default:
        break;
    }
    checkEmpty(event.target.name, v);
    console.log("fire", buttonFlg);

  }


  const triggerNext = (event) => {
    //本番ではコメントアウト消す
    let fromContents = document.querySelectorAll('.rsc-content div');
    fromContents.forEach(e => {
      e.remove();
    });
    //ここまで
    setTrigger(true, props.triggerNextStep({ trigger: 'finish3' }));
    setDisabled(true);
  }
  const telNumberCheck = (value) => {
    if (value.match(/^0[5789]0\d{8}$/)) {
      return true;
    } else if (value.match(/^0[1-9][1-9][0-9]\d{6}$/)) {
      return true;
    } else {
      return false;
    }
  }
  const onFocusCheck = (event) =>{
    event.preventDefault();
    dataLayer.push({ 'event': event.target.name });
  }
  const onBlurCheck = (event) => {
    event.preventDefault();
    console.log("onBlurCheck");
    var data = [];

    setErrorMsg('');
    if (input4Ref.current === event.relatedTarget ||
      input5Ref.current === event.relatedTarget ||
      input6Ref.current === event.relatedTarget ||
      input7Ref.current === event.relatedTarget ||
      input13Ref.current === event.relatedTarget ) {
      setErrorMsg('');
    }
    else if (kyojyu && (
      input9Ref.current === event.relatedTarget ||
      input10Ref.current === event.relatedTarget ||
      input11Ref.current === event.relatedTarget ||
      input13Ref.current === event.relatedTarget ||
      input12Ref.current === event.relatedTarget)) {
      setErrorMsg('');
    }
    else {
      if (sirName) { setErrorMsg(''); data = data.concat({ name: 'sir-name', value: sirName }); } else {
        if(disabled === true){
          alert('※名字が未入力です');
          input4Ref.current.focus();}else{
        setErrorMsg('※名字が未入力です');
          }
        return false;
      };
      if (firstName) {
        setErrorMsg(''); data = data.concat({ name: 'first-name', value: firstName });
      } else {
        if(disabled === true){
          alert('※名前が未入力です');
          input5Ref.current.focus();}else{
        setErrorMsg('※名前が未入力です');
          }
        return false;
      };
      if (sirNameKana) {
        if (!sirNameKana.match(/^[ぁ-んー]*$/)) {
          setErrorMsg(''); 
          if(disabled === true){
            alert('平仮名でふりがなを入力して下さい');
            input6Ref.current.focus();}else{
          setKanaError("平仮名でふりがなを入力して下さい");
            }
          return false;
        } else { setErrorMsg(''); setKanaError(''); data = data.concat({ name: 'sir-kana', value: sirNameKana }); }
      } else {
        if(disabled === true){
          alert('※名字（かな）が未入力です');
          input6Ref.current.focus();}else{
        setErrorMsg('※名字（かな）が未入力です');
          }
        return false;
      };
      if (firstKana) {
        if (!firstKana.match(/^[ぁ-んー]*$/)) {
          setErrorMsg('');
          if(disabled === true){
            alert('平仮名でふりがなを入力して下さい');
            input7Ref.current.focus();}else{
          setKanaError("平仮名でふりがなを入力して下さい");
            }
          return false;
        } else { setErrorMsg(''); setKanaError(''); data = data.concat({ name: 'first-kana', value: firstKana }); }
      } else {
        if(disabled === true){
          alert('※名前（かな）が未入力です');
          input7Ref.current.focus();}else{
        setErrorMsg('※名前（かな）が未入力です');
          }
        return false;
      };
      if (!tel) {
        if(disabled === true){
          alert('※電話番号が未入力です');
          input13Ref.current.focus();}else{
        setErrorMsg('※電話番号が未入力です');
          }
        return false;
      } else if (telNumberCheck(tel) === false) {
        if(disabled === true){
          alert('※電話番号が不正です');
          input13Ref.current.focus();}else{
        setErrorMsg('※電話番号が不正です');
          }
        return false;
      } else {
        setErrorMsg(''); data = data.concat({ name: 'tel', value: tel });
      };
      if (kyojyu) {
        if (prefecture) { setErrorMsg(''); } else {
          if(disabled === true){
            alert('※都道府県が未入力です');
            input9Ref.current.focus();}else{
          setErrorMsg('※都道府県が未入力です');
            }
          return false;
        };
        if (cityValue) { setErrorMsg(''); } else {
          if(disabled === true){
            alert('※市区町村が未入力です');
            input10Ref.current.focus();}else{
          setErrorMsg('※市区町村が未入力です');
            }
          return false;
        };
        if (townValue) { setErrorMsg(''); } else {
          if(disabled === true){
            alert('※町域が未入力です');
            input11Ref.current.focus();}else{
          setErrorMsg('※町域が未入力です');
            }
          return false;
        };
        if (address) { setErrorMsg(''); } else {
          if(disabled === true){
            alert('※字/丁目以降が未入力です');
            input12Ref.current.focus();}else{
          setErrorMsg('※字/丁目以降が未入力です');
            }
          return false;
        };
      }

    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var data = [];
    if (input13Ref.current === event.relatedTarget ||
      input4Ref.current === event.relatedTarget ||
      input5Ref.current === event.relatedTarget ||
      input6Ref.current === event.relatedTarget ||
      input7Ref.current === event.relatedTarget ) {
        setErrorMsg('');
    }
    else if (kyojyu && (
      input13Ref.current === event.relatedTarget ||
      input9Ref.current === event.relatedTarget ||
      input10Ref.current === event.relatedTarget ||
      input11Ref.current === event.relatedTarget ||
      input12Ref.current === event.relatedTarget)) { setErrorMsg(''); }
    else {

      if (sirName) { setErrorMsg(''); data = data.concat({ name: 'sir-name', value: sirName }); } else {
        setErrorMsg('※名字が未入力です');
        return false;
      };
      if (firstName) {
        setErrorMsg(''); data = data.concat({ name: 'first-name', value: firstName });
      } else {
        setErrorMsg('※名前が未入力です');
        return false;
      };
      if (sirNameKana) {
        if (!sirNameKana.match(/^[ぁ-んー]*$/)) {
          setErrorMsg('');
          setKanaError("平仮名でふりがなを入力して下さい");
          return false;
        } else { setErrorMsg(''); setKanaError(''); data = data.concat({ name: 'sir-kana', value: sirNameKana }); }
      } else {
        setErrorMsg('※名字（かな）が未入力です');
        return false;
      };
      if (firstKana) {
        if (!firstKana.match(/^[ぁ-んー]*$/)) {
          setErrorMsg('');
          setKanaError("平仮名でふりがなを入力して下さい");
          return false;
        } else { setErrorMsg(''); setKanaError(''); data = data.concat({ name: 'first-kana', value: firstKana }); }
      } else {
        setErrorMsg('※名前（かな）が未入力です');
        return false;
      };

      let userAddress = '';
      if (kyojyu) {
        if (prefecture) { setErrorMsg(''); } else {
          setErrorMsg('※都道府県が未入力です');
          return false;
        };
        if (cityValue) { setErrorMsg(''); } else {
          setErrorMsg('※市区町村が未入力です');
          return false;
        };
        if (townValue) { setErrorMsg(''); } else {
          setErrorMsg('※町域が未入力です');
          return false;
        };
        if (address) { setErrorMsg(''); } else {
          setErrorMsg('※字/丁目以降が未入力です');
          return false;
        };
        userAddress = prefecture + cityValue + townValue + address;
      } else {
        //getAgent依存関係
        console.log("props.steps",props.steps);
        const list = props.steps.area.value;
        const list2 = props.steps.addressDetail.value;
        console.log("list2",list);
        console.log("list2",list2);
        let prefecture2 = list.bukken_address1;
        let cityValue2 = list.bukken_address2;
        let townValue2 = list.bukken_address3;
        let address2 = list2.bukken_address4;
        let name2 = list2.bukken_name;
        let goshitsu2 = list2.bukken_address5;
        if (prefecture2 !== undefined) { userAddress = userAddress.concat(prefecture2) }
        if (cityValue2 !== undefined) { userAddress = userAddress.concat(cityValue2) }
        if (townValue2 !== undefined) { userAddress = userAddress.concat(townValue2) }
        if (address2 !== undefined) { userAddress = userAddress.concat(address2) }
        if (name2 !== undefined) { userAddress = userAddress.concat(' ', name2) }
        if (goshitsu2 !== undefined) { userAddress = userAddress.concat(' ', goshitsu2) }
      }
      if (userAddress) { data = data.concat({ name: 'address', value: userAddress }) }

      if (!tel) {
        setErrorMsg('※電話番号が未入力です');
        return false;
      } else if (telNumberCheck(tel) === false) {
        setErrorMsg('※電話番号が不正です');
        return false;
      } else {
        setErrorMsg(''); data = data.concat({ name: 'tel', value: tel });
      };
      let jiki = props.steps.bukkenQa2.value.jiki;
      let reson = props.steps.bukkenQa2.value.reson;
      //テストデータ
      // let jiki = "いつでも";
      // let reson = "売却のため";
      //テストデータここまで
      if (jiki) { data = data.concat({ name: 'jiki', value: jiki }) };
      if (reson) { data = data.concat({ name: 'reson', value: reson }); };

      props.step.value = data;
      if(disabled === false){
      triggerNext();
      }
    }
  };


  useEffect(() => {
    const propsObject = props.steps;
    console.log("propsObject", propsObject);
    // if(props.steps.genkyoSelect.value !== '住んでいます'){
    //   setKyojyu(true);
    // };
    // dataLayer.push({ 'event': "sirNameFocus" });
  }, []);
  useEffect(() =>{
    console.log("kyojyuState",kyojyuState);
    const propsObject = props.steps;
                    console.log("propsObject", propsObject);
    if(kyojyuState === 1 ){
      setKyojyu(false);
      console.log("setKyojyu(false);");
    }else if(kyojyuState === 2 ){
      setKyojyu(true);
      console.log("setKyojyu(true);");
    }
  },[kyojyuState]);




  return (
    <form className="formCss2 contactInfo back-white-wrapper" onSubmit={handleSubmit}>
      <label>氏名：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className='spBr'></br>
        <input placeholder="名字" type="text" name="sirName" value={sirName} className="sirnameInput" onChange={inputChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input4Ref} autoFocus />
        <input placeholder="名前" type="text" name="firstName" id="firstName" className="firstnameInput" value={firstName} onChange={inputChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input5Ref} /></label><br></br>
      <label>ふりがな:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className='spBr'></br>
        <input placeholder="みょうじ" type="text" name="sirNameKana" className="sirnameInput" value={sirNameKana} onChange={inputChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input6Ref} />
        <input placeholder="なまえ" type="text" name="firstKana" className="firstnameInput" value={firstKana} onChange={inputChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input7Ref} /></label><br></br>
      <p className='errMsg'>{kanaError}</p>
      {kyojyu && <>
        <label>都道府県
          <select className="ownerTodofuken" value={prefecture} onChange={handleChange} name="prefecture" defaultValue="" onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input9Ref}>
            <option value='' disabled>都道府県を選択して下さい</option>
            {prefectures?.map(d => { return <><option value={d}>{d}</option></> })}
          </select>
        </label><br></br>
        <label>市区町村：
          <select className="ownerShikucyoson" value={cityValue} onChange={handleChange} defaultValue="" name='cityValue' onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input10Ref}>
            <option value='' disabled>市区町村を選択して下さい</option>
            <Suspense fallback={<p>Loading..</p>}>
              <SetCities property={prefecture}></SetCities>
            </Suspense>
          </select>
        </label><br></br>
        <label>
          町域：
          <select className="ownerChoiki" value={townValue} onChange={handleChange} defaultValue="" name='townValue' onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input11Ref}>
            <option value='' disabled>町域を選択して下さい</option>
            <Suspense fallback={<p>Loading..</p>}>
              <SetTowns prefecture={prefecture} townProperty={townSetValue}></SetTowns>
            </Suspense>
          </select>
        </label><br></br>
        <label>字/丁目以降：<input className="ownerAddress" type="text" name="address" value={address} onChange={inputChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input12Ref} /></label><br></br>
      </>}


      <label>電話番号：※ハイフン無し半角数字<input className='tel' placeholder="" type="tel" name="tel" value={tel} onChange={handleChange} onFocus={onFocusCheck} onBlur={onBlurCheck} ref={input13Ref} /></label>
      <br></br>
      <p className='errMsg'>{errorMessage}</p>
      {buttonFlg && <><button className="btn btn-success" disabled={disabled} formNoVlidate>
        査定業社の選択へ
      </button><p className='color-red'>※入力情報がお間違えないかご確認ください。</p></>}
      <div><p className='color-red'>※入力された情報が、お客様の許可なしに一般に公開されることは一切ありません。</p></div>
    </form>
  );
}