import React, { Component } from 'react';
import './assets/css/chat.css';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import ChatBot from 'react-simple-chatbot';
import BukkenType from './chatComponents/bukkenType';
import CitySelect2 from './chatComponents/citySelect2';
import PropertyData from './chatComponents/propertyData';
import GetAgents from './chatComponents/getAgents';
import UserInfo from './chatComponents/userInfo';
import Contact1 from './chatComponents/contact1';
import Contact2 from './chatComponents/contact2';
import SateiStart0LnLp from './chatComponents/sateiStart_0_lnlp';
import BukkenQa from './chatComponents/bukkenQa';
import Other from './chatComponents/other';
import Genkyo from './chatComponents/genkyo';
import Kento from './chatComponents/kento';
import SaiKento from './chatComponents/saiKento';
import Area from './chatComponents/area';
import Todofuken0 from './chatComponents/todofuken0';

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, gender, age } = steps;

    this.setState({ name, gender, age });
  }

  render() {
    const { name, gender, age } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <h3>Summary</h3>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{name.value}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{gender.value}</td>
            </tr>
            <tr>
              <td>Age</td>
              <td>{age.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}



Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};



class ChatForm0LnLp extends Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <script>{(function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
              'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-TC8ZBW4')}</script>
          {/* <!-- End Google Tag Manager --> */}
          <title>イエカカク一括査定申し込み</title>
          <meta name="description" content="最大６社を比較して、最高価格でおうちを売却！オンラインで査定価格を一括お取り寄せ！実績豊富な厳選企業をマッチング！！" />

          <meta property="og:site_name" content="イエカカク" />
          <meta property="og:title" content="イエカカク" />
          <meta property="og:description" content="最大６社を比較して、最高価格でおうちを売却！オンラインで査定価格を一括お取り寄せ！実績豊富な厳選企業をマッチング！！" />
          <meta property="og:image" content="https://iekakaku.jp/assets/img/iekakaku_1200x630.png" />
          <meta property="og:image:alt" content="画像の説明文" />
          <meta property="og:locale" content="ja_JP" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://iekakaku.jp/" />
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="shortcut icon" href="/assets/img/favicon.ico" />
        </Helmet>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TC8ZBW4"
          height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}

        <header className="headerChatForm">
          <h1><div><img src="./assets/img/logo.png" alt="イエカカク" /><div>完全無料</div></div></h1>

        </header>
        <ChatBot hideHeader='true' botAvatar="./assets/img/avator2_edt.png" hideUserAvatar='true' customStyle={{ padding: '0', backgroundColor: 'transparent', boxShadow: 'none', borderRadius: 'none' }}
          steps={[
            {
              id: 'bukkenType',
              component: <BukkenType {...this.props} />,
              waitAction: true,
              //trigger: 'getAgents',//test
              inputAttributes: {
                name: 'bukken-type',
                id: 'bukken-type',
              },
            },
            {
              id: 'bukkenTypeAnswer',
              message: 'イエカカクをご利用いただき、ありがとうございます。{previousValue}の査定をご希望ですね！かしこまりました。',
              trigger: '3',
              //trigger: 'propertyData', //test
              //trigger: 'userInfo', //test
            },
            {
              id: 'otherMessage',
              message: 'その他の物件種別ですね。下の中から詳しい物件種別を選択してください。',
              trigger: 'other',
            },
            {
              id: 'other',
              component: <Other {...this.props} />,
              waitAction: true,
              trigger: 'bukkenTypeAnswer',
              inputAttributes: {
                name: 'bukken-type',
                id: 'bukken-type',
              },
            },
            {
              id: '3',
              message: '査定したい物件のエリアを選択して下さい',
              trigger: 'area',
            },
            {
              id: 'area',
              component: <Area {...this.props} />,
              waitAction: true,
              inputAttributes: {
                name: 'area-input',
                id: 'area-input',
              },
            },
            {
              id: 'todofuken0Message',
              message: '都道府県を選んで下さい',
              trigger: 'todofuken0',
            },
            {
              id: 'todofuken0',
              component: <Todofuken0 {...this.props} />,
              waitAction: true,
              inputAttributes: {
                name: 'area-input',
                id: 'area-input',
              },
            },
            {
              id: 'todofukenMessage',
              message: '物件の詳しい住所を教えて下さい。',
              trigger: 'todofuken',
              inputAttributes: {
                name: 'reration',
                id: 'reration',
              },
            },
            {
              id: 'todofuken',
              component: <CitySelect2  {...this.props} />,
              waitAction: true,
              trigger: 'genkyo',
              //trigger: 'getAgents',//test
              inputAttributes: {
                name: 'todofuken-select',
                id: 'todofuken-select',
              },
            },
            {
              id: 'genkyo',
              message: '現在こちらの物件にお住まいですか？',
              trigger: 'genkyoSelect',
              inputAttributes: {
                name: 'reration',
                id: 'reration',
              },
            },
            {
              id: 'genkyoSelect',
              component: <Genkyo {...this.props} />,
              waitAction: true,
              trigger: 'propertyInfo',
              inputAttributes: {
                name: 'genkyoSelect',
                id: 'genkyoSelect',
              },
            },
            {
              id: 'propertyInfo',
              message: '物件の情報を教えて下さい。分かる範囲で問題ございません。',
              trigger: 'propertyData',
              // trigger: 'propertyInfo2',
              inputAttributes: {
                name: 'reration',
                id: 'reration',
              },
            },
            {
              id: 'propertyData',
              component: <PropertyData  {...this.props} />,
              waitAction: true,
              //trigger:'getAgents',//test
              inputAttributes: {
                name: 'todofuken-select',
                id: 'todofuken-select',
              },
            },
            {
              id: 'bukkenQa',
              component: <BukkenQa  {...this.props} />,
              waitAction: true,
              inputAttributes: {
                name: 'reration',
                id: 'reration',
              },
            },
            {
              id: 'kento',
              message: 'イエカカクは売却を検討する方向けのサービスです。査定結果を知って、売却を検討する可能性はありますか？',
              trigger: 'kentoSelect',
            },
            {
              id: 'kentoSelect',
              component: <Kento {...this.props} />,
              waitAction: true,
              //trigger: 'getAgents',//test
              inputAttributes: {
                name: 'kentoSelect',
                id: 'kentoSelect',
              },
            },
            {
              id: 'suspend',
              message: '大変申し訳ありません。イエカカクは売却意思のある方のみを対象としており、査定金額の算出のみのためにご利用いただけません。',
              trigger: 'saiKento',
            },
            {
              id: 'saiKento',
              message: '査定結果を知って、売却を検討する可能性はありますか？',
              trigger: 'saiKentoSelect',
            },
            {
              id: 'saiKentoSelect',
              component: <SaiKento {...this.props} />,
              waitAction: true,
              //trigger: 'getAgents',//test
              inputAttributes: {
                name: 'saiKentoSelect',
                id: 'saiKentoSelect',
              },
            },
            {
              id: 'suspendToend',
              message: 'ご利用ありがとうございました。具体的に売却を検討されるタイミングになりましたら、再度ご利用いただけますようお願い申し上げます。',
              end: true,
            },
            {
              id: 'finish1',
              message: 'ここまでご入力お疲れ様でした。現在、いただいた物件情報をもとに査定企業を探しております。',
              trigger: 'getAgents',
            },
            {
              id: 'getAgents',
              component: <GetAgents  {...this.props} />,
              waitAction: true,
              trigger: 'userInfo',
              inputAttributes: {
                name: 'getAgents',
                id: 'getAgents',
              },
            },
            {
              id: 'userInfo',
              component: <UserInfo  {...this.props} />,
              waitAction: true,
              trigger: 'finish2',
              inputAttributes: {
                name: 'userInfo',
                id: 'userInfo',
              },
            },
            {
              id: 'noAgent',
              message: '大変申し訳ございません、お客様の物件に対応できる不動産会社を見つけることができませんでした。月を跨ぐと対応できる業者を見つけられる場合がございます。大変恐縮ですが、また何日かお時間を開けてから、再度お試しいただけますと幸いです。',
              end: true,
            },
            {
              id: 'failData',
              message: '不動産会社データ取得に失敗しました。ページを読み込み直して、再度査定情報の入力をお願いいたします。再度申し込みが失敗した場合は、大変お手数ですが、info@mail.iekakaku.jpまでご連絡をお願いいたします。',
              end: true,
            },
            {
              id: 'finish2',
              message: 'お客様の物件に対応可能な不動産会社が見つかりました。査定結果の連絡先を入力してください。',
              trigger: 'finish2b',
            },
            {
              id: 'finish2b',
              message: '残り4問です。',
              trigger: 'contact1',
            },
            {
              id: 'contact1',
              component: <Contact1 {...this.props} />,
              waitAction: true,
              trigger: 'contactInfo',
              inputAttributes: {
                name: 'contactInfo',
                id: 'contactInfo',
              },
            }, {
              id: 'contactInfo',
              component: <Contact2  {...this.props} />,
              waitAction: true,
              trigger: 'finish3',
              inputAttributes: {
                name: 'contactInfo',
                id: 'contactInfo',
              },
            },
            {
              id: 'finish3',
              message: 'お疲れさまでした。入力は以上です。「無料査定スタート」のボタンを押して下さい。',
              trigger: 'sateiStart',
            },
            {
              id: 'sateiStart',
              component: <SateiStart0LnLp  {...this.props} />,
              waitAction: true,
              trigger: 'end-message',
              inputAttributes: {
                name: 'sateiStart',
                id: 'sateiStart',
              },
            },
            {
              id: 'end-message',
              message: 'ご利用ありがとうございました。不動産会社からの査定結果をお待ち下さい。',
              end: true,
            },
          ]}
        />
        <footer>運営会社：<a href='https://budi-tech.com/about-us/' target="_blank" rel="noopener noreferrer">株式会社budi tech</a>© 2022 budi tech　<a href="https://budi-tech.com/privacy/" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a></footer>
      </>
    );
  }
}

export default ChatForm0LnLp;