import React, {Suspense, useState, useEffect } from 'react';

export default function TownSelect(props){
  const [errorMessage, setErrorMsg] = useState();
  const [trigger, setTrigger] = useState(false);
  const [townValue, setTownValue] = useState();

  const [property, setProperty] = useState();
  const [flgData, setFlgData] = useState();
  const [data, setData] = useState();
  const [townData, setTownData] = useState();
  
  const triggerNext = (event) =>{
    setTrigger(true, props.triggerNextStep({trigger: 'addressDetail'}));
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if(event.target.value){setErrorMsg(''); }else{
      setErrorMsg('※市区町村が未入力です');
      return false;
    };
    
    props.step.value = event.target.value;
    triggerNext();
  };
  
  useEffect(() =>{
      //  if(props.steps.citySelect3.value){
       //テストデータ
       if(true){
      //   let prefacture = "東京都";
      // let cityName = "渋谷区";
      let prefacture = props.steps.todofuken0.value;
      let cityName = props.steps.citySelect3.value;
      const jsonData = require('../../components/assets/js/prefJson/47todofuken.json');
      var cityData = jsonData.response.prefecture.find((v) => v.city == prefacture);
      if (cityData) {
        // let townData = require('../../components/assets/js/prefJson/' + cityData.city_eng + 'Town.json');
        // console.log("townData",townData);
        //テストデータここまで
       const URL = 'https://geoapi.heartrails.com/api/json?method=getTowns&city='+ cityName;
      //テストデータ
      // const testData = 
      // let towns = testData.response.location;;
      //テストデータここまで
         fetch(URL)
         .then(res => {let rere = res.json(); return rere;})
          .then(d => {console.log("d",d.response.location);
            let towns;
            switch(cityName){
              case "府中市":
              case "伊達市":
               towns = d.response.location.filter(value => { return value.prefecture === prefacture});
               break;
              default:
                towns = d.response.location; 
                break;
              }
              return towns;
          })
          .then(t => {if(t){
            //ここから処理入れる
            let towns = t;
            towns.sort(function(a, b){
              if(a.town_kana > b.town_kana) {
                return 1;
              }
              if(a.town_kana < b.town_kana) {
                return -1;
              }
              return 0;
            });
            const akasatanaFlg = [
              {gyo:"a", kana:"あ", disabled :true},{gyo: "ka", kana:"か",disabled :true},{gyo: "sa", kana:"さ",disabled :true},{gyo: "ta", kana:"た", disabled :true},{gyo: "na", kana:"な",disabled :true},{gyo: "ha", kana:"は",disabled :true},{gyo: "ma", kana:"ま",disabled :true},{gyo: "ya", kana:"や", disabled :true},{gyo: "ra", kana:"ら",disabled :true},{gyo: "wa", kana:"わ",disabled :true}
            ];
            const akasatana = [
              {gyo:"a",  list:[] , display: "none"},{gyo: "ka",  list:[] , display: "none"},{gyo: "sa",  list:[] , display: "none"},{gyo: "ta",  list:[] , display: "none"},{gyo: "na",  list:[] , display: "none"},{gyo: "ha",  list:[] , display: "none"},{gyo: "ma",  list:[] , display: "none"},{gyo: "ya",  list:[] , display: "none"},{gyo: "ra",  list:[] , display: "none"},{gyo: "wa",  list:[],display: "none"}
            ];
        
            let displayFlg = true;
            towns.forEach(element => {
              switch(true){
                case/^[ぁ-お]/.test(element["town_kana"]):
                akasatana[0].list.push(element);
                if(displayFlg){
                  akasatana[0].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[0].disabled = false;
                break;
                case/^[か-ご]/.test(element["town_kana"]):
                akasatana[1].list.push(element);
                if(displayFlg){
                  akasatana[1].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[1].disabled = false;
                break;
                case/^[さ-ぞ]/.test(element["town_kana"]):
                akasatana[2].list.push(element);
                if(displayFlg){
                  akasatana[2].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[2].disabled = false;
                break;
                case/^[た-ど]/.test(element["town_kana"]):
                akasatana[3].list.push(element);
                if(displayFlg){
                  akasatana[3].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[3].disabled = false;
                break;
                case/^[な-の]/.test(element["town_kana"]):
                akasatana[4].list.push(element);
                if(displayFlg){
                  akasatana[4].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[4].disabled = false;
                break;
                case/^[は-ぽ]/.test(element["town_kana"]):
                akasatana[5].list.push(element);
                if(displayFlg){
                  akasatana[5].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[5].disabled = false;
                break;
                case/^[ま-も]/.test(element["town_kana"]):
                akasatana[6].list.push(element);
                if(displayFlg){
                  akasatana[6].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[6].disabled = false;
                break;
                case/^[ゃ-よ]/.test(element["town_kana"]):
                akasatana[7].list.push(element);
                if(displayFlg){
                  akasatana[7].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[7].disabled = false;
                break;
                case/^[ら-ろ]/.test(element["town_kana"]):
                akasatana[8].list.push(element);
                if(displayFlg){
                  akasatana[8].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[8].disabled = false;
                break;
                case/^[ゎ-ん]/.test(element["town_kana"]):
                akasatana[9].list.push(element);
                if(displayFlg){
                  akasatana[9].display = "block";
                  displayFlg = false;
                }
                akasatanaFlg[9].disabled = false;
                break;
                default:
                  break;
              }
            });
            setFlgData(akasatanaFlg);
            setData(akasatana);
          }})
         .catch(e => console.log(e))
        }
    
  }
    //ここまで
  },[]);
  
  const displayChange = (event) =>{
    event.preventDefault();
    let clsName = event.target.className;
    console.log("clsName",clsName);
    let gyo =   clsName.split('-');
    console.log("gyo",gyo);
    let spcfGyo = "list-"+gyo[2];
    console.log("spcfGyo",spcfGyo);
    let parent = document.querySelector('.cyoiki');
  let els = parent.children;
  console.log("els",els);
  for(const el of Array.from(els)) {
    if(el.className.match(spcfGyo)){
    el.style.display = "block";
    }else{
      el.style.display = "none";
    }
  }
  }
    return (
    <>
    <form onSubmit={handleSubmit} className="formCss1 townSelect back-white-wrapper list-form" id="townSelect">
      <Suspense fallback={<p>Loading..</p>}>
      <div className='list-header'>
      <p>町域を選択して下さい</p>
      <div className="akasatanaButton">
        {flgData?.map( f => { return <><button className={'town-button1 button-'+f.gyo} disabled={f.disabled} onClick={displayChange}>{f.kana}</button></>})}</div>
        </div>
      <div className="cyoiki">{data?.map( d => { return <><div className={"list-button list-"+d.gyo} style={{ display: d.display}}>{d.list?.map( c => {return <><button className={'town-button2'} key={c.town_kana} value={c.town} onClick={handleSubmit}>{c.town}</button></>})}</div></>})}</div>
      </Suspense>
      <p className='errMsg'>{errorMessage}</p>
      <p className="color-grey syutten"><span>住所情報出典:「位置参照情報」(国土交通省)の加工情報・「HeartRails Geo API」(HeartRails Inc.)</span></p>
      </form>
      </>
    );

    
}